.searchPageContainer {
    justify-content: center;
    display: inline-grid;
}

.searchPageForm {
    display: grid;
    width: 300px;
    margin: 15px 0
}

#searchMessageResult {
    font-weight: 600;
    width: 300px;
}

#searchMessageResult.error {
    color: red;
}

#searchMessageResult.success {
    color: green;
}