.logInPageContainer {
    display: grid;
}

.logInPageContent {
    justify-content: center;
    display: inline-grid;
}

.logInPageContainer header {
    display: flex;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    height: 48px;
    background-color: #B0DA00;
}

.logInPageContainer header span {
    color: white;
    font-family: sans-serif;
    font-size: 1.25rem;
    margin: 12px;
    position: absolute;
}

.logInPageContainer button {
    margin: 10px auto;
}

#notAllowedMessage {
    font-weight: 600;
    width: 300px;
    color: red;
}