.uploadFilePageContainer {
    justify-content: center;
    display: inline-grid;
}

.uploadFileForm {
    display: grid;
    margin: 15px 0;
}

.uploadFileForm input {
    width: 214px;
}

#uploadMessageResult {
    font-weight: 600;
    width: 300px;
}

#uploadMessageResult.error {
    color: red;
}

#uploadMessageResult.success {
    color: green;
}