.MuiDrawer-root {
    background: white;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    z-index: 5;
}

#react-admin-title {
    width: 120px !important;
}

#LLT-logo {
    margin: auto;
}

header button[aria-label="Refresh"] {
    display: none;
}

.mobileLayout .RaLayout-appFrame {
    margin-top: 96px !important;
}