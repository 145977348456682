.historicalContainer {
    justify-content: center;
    display: inline-grid;
}

.historicalForm {
    display: grid;
    height: 610px;
    width: 300px;
    margin: 10px auto;
}

.historicalForm [role="progressbar"] {
    margin: auto;
}

.historicalError {
    font-weight: 600;
    width: 300px;
    color: red;
}

#downloadErrorAlert {
    position: absolute;
}

.dateUserUploader {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 260px;
}

#historicalPagination {
    margin: 0 auto 10px;
}